export interface ErrorResponse<T = any> {
  code: string
  message: string
  data?: T
}

/**
 *   BIZ_01xxxx: configuration-service
 */
export enum ConfigSvcError {}

/**
 *   BIZ_02xxxx: product-service
 */
export enum ProductSvcError {
  SEARCH_INVALID = 'BIZ_020003',
  CATALOG_NOT_FOUND = 'BIZ_020004',
  SECOND_CATEGORY_NOT_FOUND = 'BIZ_020015',
}

/**
 *   BIZ_03xxxx: customer-service
 */
export enum CustomerSvcError {
  BANNER_NOT_FOUND = 'BIZ_030001',
  CHANNEL_NOT_FOUND = 'BIZ_030002',
  USER_NOT_FOUND = 'BIZ_030003',
  CUSTOMER_NOT_FOUND = 'BIZ_030004',
  PARAMETER_INVALID = 'BIZ_030005',
  EMAIL_OR_TEL_ALREADY_EXISTS = 'BIZ_030006',
  CUSTOMER_UNAVAILABLE = 'BIZ_030007',
  USERNAME_INVALID = 'BIZ_030008',
  ADDRESSES_NUMBER_EXCEED = 'BIZ_030015',
  ADDRESS_ID_INVALID = 'BIZ_030016',
  REGISTRATION_REQUEST_SUBMITTED = 'BIZ_030018',
  ACCOUNT_ALREADY_EXISTS = 'BIZ_030019',
}

/**
 *   BIZ_04xxxx: checkout-service
 */
export enum CheckoutSvcError {
  PRODUCT_INVALID = 'BIZ_040001',
  PRODUCT_OUT_DATED = 'BIZ_040002',
  PRODUCTS_INVALID = 'BIZ_040003',
  QUOTATION_NOT_FOUND = 'BIZ_040004',
  QUOTATION_STATUS_INVALID = 'BIZ_040005',
  QUOTATION_ACCESS_DENIED = 'BIZ_040006',
  INVALID_OPERATION = 'BIZ_040007',
  QUOTATION_PDF_GENERATE_FAILED = 'BIZ_040008',
  COUNTERSIGNS_NUMBER_EXCEED = 'BIZ_040009',
  ORDER_NOT_FOUND = 'BIZ_040014',
  ORDER_PAYMENT_DUPLICATED = 'BIZ_040022',
  ORDER_E_PAY_ERROR = 'BIZ_040032',
}

/**
 *   BIZ_05xxxx: inventory-service
 */
export enum InventorySvcError {}

/**
 *   BIZ_06xxxx: quotation-service
 */
export enum QuotationSvcError {
  PROMOTION_UNAVAILABLE = 'BIZ_060008',
  PROMOTION_NOT_FOUND = 'BIZ_060009',
  PROMOTION_ACCESS_DENIED = 'BIZ_060010',
  PROMOTION_NA_UNAVAILABLE = 'BIZ_060013',
  PROMOTION_NA_NOT_FOUND = 'BIZ_060014',
}

export enum CommonSvcError {
  SERVICE_ERROR = 'SERVICE_ERROR',
  SYS_EXCEPTION = 'SYS_EXCEPTION',
}
/**
 *   Backend Errors
 */
export const ErrorsRemote: Record<string, string> = {
  [CommonSvcError.SERVICE_ERROR]: '系统异常，请稍后再试',
  [CommonSvcError.SYS_EXCEPTION]: '系统异常，请稍后再试',

  [ProductSvcError.SEARCH_INVALID]: '输入内容不合法',
  [ProductSvcError.CATALOG_NOT_FOUND]: '未找到该产品目录',
  [ProductSvcError.SECOND_CATEGORY_NOT_FOUND]: '未找到该二级分类',

  [CustomerSvcError.BANNER_NOT_FOUND]: '未找到该渠道对应的 Banner',
  [CustomerSvcError.CHANNEL_NOT_FOUND]: '渠道不存在',
  [CustomerSvcError.USER_NOT_FOUND]: '用户名不存在，请联系客服',
  [CustomerSvcError.CUSTOMER_NOT_FOUND]: '当前客户不存在，请联系客服',
  [CustomerSvcError.PARAMETER_INVALID]: '接口调用参数错误',
  [CustomerSvcError.EMAIL_OR_TEL_ALREADY_EXISTS]: '邮箱或手机号已被使用',
  [CustomerSvcError.CUSTOMER_UNAVAILABLE]: '该客户尚不可用',
  [CustomerSvcError.USERNAME_INVALID]: '用户已失效，请联系客服',
  [CustomerSvcError.ADDRESSES_NUMBER_EXCEED]: '地址数量最多支持5条',
  [CustomerSvcError.ADDRESS_ID_INVALID]: '网络错误，请刷新后重试',
  [CustomerSvcError.REGISTRATION_REQUEST_SUBMITTED]: '您已提交过注册需求，请勿重复提交！',
  [CustomerSvcError.ACCOUNT_ALREADY_EXISTS]: '您已注册过商城帐号，使用您的手机号码/邮箱即可登录使用',

  [CheckoutSvcError.PRODUCT_INVALID]: '产品已失效，请重新选择产品进行报价',
  [CheckoutSvcError.PRODUCT_OUT_DATED]: '产品信息已变更，请刷新页面获取最新信息',
  [CheckoutSvcError.PRODUCTS_INVALID]: '产品均已失效，请重新选择产品进行报价',
  [CheckoutSvcError.QUOTATION_NOT_FOUND]: '报价单不存在',
  [CheckoutSvcError.QUOTATION_ACCESS_DENIED]: '报价单不存在',
  [CheckoutSvcError.QUOTATION_STATUS_INVALID]: '状态已变更, 请刷新页面查看',
  [CheckoutSvcError.QUOTATION_PDF_GENERATE_FAILED]: '下载失败，请重试',
  [CheckoutSvcError.COUNTERSIGNS_NUMBER_EXCEED]: '最多支持上传10个文件',
  [CheckoutSvcError.ORDER_NOT_FOUND]: '订单不存在',
  [CheckoutSvcError.INVALID_OPERATION]: '非法操作',
  [CheckoutSvcError.ORDER_PAYMENT_DUPLICATED]: '该订单正在支付中，请勿重复支付。',
  [CheckoutSvcError.ORDER_E_PAY_ERROR]: '使用电子账户支付出现错误',

  [QuotationSvcError.PROMOTION_UNAVAILABLE]: '活动不可查看',
  [QuotationSvcError.PROMOTION_NOT_FOUND]: '活动不存在',
  [QuotationSvcError.PROMOTION_ACCESS_DENIED]: '无权限查看',
  [QuotationSvcError.PROMOTION_NA_UNAVAILABLE]: '活动不可查看',
  [QuotationSvcError.PROMOTION_NA_NOT_FOUND]: '活动不存在',
  '10044': '请勿重复操作',
} as const

/**
 * BFF errors
 */
export enum ErrorCode {
  UNKNOWN = 'ERR_000000',
  AUTH_FAILED = 'ERR_000401',
  FORBIDDEN = 'ERR_000403',
  NOT_FOUND = 'ERR_000404',
  METHOD_NOT_ALLOWED = 'ERR_000405',

  TOKEN_REFRESH_FAILED = 'ERR_000002',
  OTP_SEND_FAILED = 'ERR_000003',
  OTP_MAXIMUM_EXCEED = 'ERR_000004',
  OTP_VERIFY_FAILED = 'ERR_000005',
  OTP_EXPIRED = 'ERR_000006',
  OTP_RETRY_EXCEED = 'ERR_000007',
  CODE_MISSING = 'ERR_000008',
  ACCOUNT_MISSING = 'ERR_000009',
  USER_INFO_CHANGED = 'ERR_000010',

  PRODUCT_NOT_EXIST = 'ERR_020001',
  PRODUCT_INVALID = 'ERR_020002',
  THIRD_CATEGORY_ID_INVALID = 'ERR_020003',

  ADDRESS_TYPE_INVALID = 'ERR_040001',
  QUOTATION_COUNTERSIGN_SIZE_EXCEED = 'ERR_040002',
  REQUEST_TOO_FREQUENT = 'ERR_040003',
  QUOTATION_STATUS_INVALID = 'ERR_040004',
  QUOTATION_COUNTERSIGN_EXTENSION_INVALID = 'ERR_040005',
}

export const errorsDeclared: Record<string, string> = {
  [ErrorCode.UNKNOWN]: '系统异常，请稍后再试',
  [ErrorCode.AUTH_FAILED]: '登录已失效，请重新登录',
  [ErrorCode.FORBIDDEN]: '没有权限访问',
  [ErrorCode.NOT_FOUND]: '内容不存在',
  [ErrorCode.METHOD_NOT_ALLOWED]: '接口不存在',
  [ErrorCode.TOKEN_REFRESH_FAILED]: '登录失败',
  [ErrorCode.USER_INFO_CHANGED]: '用户信息发生变更, 请重新登录',
  [ErrorCode.OTP_SEND_FAILED]: '验证码邮件发送失败，请重试',
  [ErrorCode.OTP_MAXIMUM_EXCEED]: '验证码获取次数频繁，请1小时后重试',
  [ErrorCode.CODE_MISSING]: '缺少验证码',
  [ErrorCode.ACCOUNT_MISSING]: '缺少邮箱或手机',
  [ErrorCode.OTP_VERIFY_FAILED]: '您输入的验证码不正确，请重新输入',
  [ErrorCode.OTP_EXPIRED]: '验证码已过期，请重新接收验证码后再试',
  [ErrorCode.OTP_RETRY_EXCEED]: '验证码重试次数已超过5次，请重新获取验证码后再试',
  [ErrorCode.PRODUCT_NOT_EXIST]: '商品不存在',
  [ErrorCode.PRODUCT_INVALID]: '产品已失效',
  [ErrorCode.ADDRESS_TYPE_INVALID]: '报价单地址类型不匹配',
  [ErrorCode.QUOTATION_COUNTERSIGN_SIZE_EXCEED]: '文件尺寸过大，请重新选择',
  [ErrorCode.QUOTATION_COUNTERSIGN_EXTENSION_INVALID]: '文件类型不合法，请重新选择',
  [ErrorCode.REQUEST_TOO_FREQUENT]: '操作频繁，请稍后再试',
  [ErrorCode.QUOTATION_STATUS_INVALID]: '订单状态异常，请刷新页面查看',
}
